import React from 'react'
import { graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { IndexQueryQuery, PostByPathQuery } from '../../types/graphql-types'
import Meta from '../components/meta/meta'
import Layout from '../components/layout/layout'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import { Link } from 'gatsby'
import Video from "components/video"
import BackgroundImage from 'gatsby-background-image'
import OGImage from 'images/the-dentists-at-gateway-crossing.jpg'
import Logo from 'images/logo.png'
import { Helmet } from 'react-helmet'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const BlogPostTwoPage: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const implants_img = data.implants_img?.childImageSharp?.fluid

  return (
    <Layout location={location}>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>Are Dental Implants Worth It?- The Dentists At Gateway Crossing</title>
      <meta name="description" content="Dental implants are a common treatment for missing teeth and continue to be one of the most popular treatments today. But do they work?" />
      <meta name="robots" content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="McCordsville Dentist Office The Dentists At Gateway Crossing" />
      <meta property="og:description" content="Dental implants are a common treatment for missing teeth and continue to be one of the most popular treatments today. But do they work?" />
      <meta property="og:url" content="https://thedentistsatgc.com/" />
      <meta property="og:site_name" content="The Dentists At Gateway Crossing" />
      <meta property="og:updated_time" content="2021-02-05T05:10:25+00:00" />
      <meta property="og:image" content={OGImage} />
      <meta property="og:image:secure_url" content={OGImage} />
      <meta property="og:image:width" content="1080" />
      <meta property="og:image:height" content="1080" />
      <meta property="og:image:alt" content="McCordsville Dentist" />
      <meta property="og:image:type" content="image/jpeg" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="McCordsville Dentist Office The Dentists At Gateway Crossing" />
      <meta name="twitter:description" content="Dental implants are a common treatment for missing teeth and continue to be one of the most popular treatments today. But do they work?" />
      <meta name="twitter:image" content={OGImage} />
      <meta name="google-site-verification" content="khYQGQ-T8YWkXa0QyKr6e2kNlFB3l0ZiFRA89VzVbaM" />
      <meta name="google-site-verification" content="Oll6PjpMRzNx1KbgKilxS6xPddvSbF2lDOa2rd7VkxA" />
      <meta name="msapplication-TileImage" content={Logo} />
      <script async src='https://tag.simpli.fi/sifitag/195cd770-bf42-013a-5143-0cc47a8ffaac'></script>
    </Helmet>
        <section id="blog-post-section">
          <Container>
            <div className="col-lg-12">
              <h1>
                Dental Implants
              </h1>
              <ul className="list-inline">
                <li className="list-inline-item article-time">
                  2021/07/06
                </li>
                <li className="list-inline-item badge badge-primary">
                  Dental Implants
                </li>
                <li className="list-inline-item badge badge-secondary">
                  Are dental implants worth it?
                </li>
              </ul>
                <Img fluid={implants_img} className="blog-post-img"/>
              <h2 className="mb-3">
                Are dental implants worth it?
              </h2>
              <p>
                Dental implants are a common treatment for missing teeth. They have been around since the 1960s and continue to be one of the most popular treatments today. But do they work? Do dental implants actually improve your quality of life? In this blog post, we will explore how dental implants can help you eat better, feel better, and smile more!
              </p>
              <p>
                If you have a tooth that needs to be extracted, you should consider getting a dental implant.
              </p>
              <p>
                One of the most important things to know about dental implants is that they look and function almost identically to natural teeth. This is because the implant screws into your jawbone, just like a natural tooth root would. You also have to take care of them with brushing and flossing as you do your other teeth to keep dental implants healthy!
              </p>
              <p>
                When it comes down to it, dental implants are worth it if you want more confidence in yourself. They can improve how you feel about yourself, but they are also going to improve your oral health. That is because dental implants help to prevent bone loss in your jawbone.
              </p>
              <h3 className="mb-3">
                How do they work?
              </h3>
              <p>
                A dental implant is a titanium screw that is permanently placed into your jaw where the root would have been. After a period of time for your jaw to heal from the operation, an abutment with a crown is placed onto the dental implant. This crown will look and function just like healthy natural teeth.
              </p>
              <p>
                What are the benefits of dental implants?
              </p>
              <h4>
                Dental implants offer the following benefits:
              </h4>
              <ul className="blog-list">
                <li>
                  Improved oral health; tooth loss is avoided due to bone grafting
                </li>
                <li>
                  Longer lasting, as dental implants will last a lifetime with proper care and maintenance. If you are missing one or more teeth in your mouth for an extended period of time, this can lead to bone loss in the jaw.
                </li>
              </ul>
              <p>
                If you have a dental implant, the bone will be protected and preserved due to the titanium screw that was implanted.
              </p>
              <h3 className="mb-3">
                What are the disadvantages to dental implants?
              </h3>
              <p>
                There's really one major disadvantage to dental implants. Cost - The cost of dental implants can prove to be an obstacle for some people.
              </p>
              <p>
                However, dental implants are a long-term investment with many benefits. If you can afford it, dental implants can truly be a life changing transformation.
              </p>
              <p>
                Many people who report having had dental implants say that it is the best money they have ever spent on themselves.
              </p>
              <p>
                It's an investment in yourself!
              </p>
              <p>
                Other small disadvantages to dental implants could include pain, swelling, bleeding, as might be expected with any type of oral surgery.
              </p>
              <p>
                It is important to realize that with this investment comes the need to maintain proper hygiene, not just for your new dental implant, but also for any remaining healthy teeth.
              </p>
              <p>
                It's important to brush and floss after every meal in order to maintain healthy teeth gums as well as keep food from getting trapped around the implant site.
              </p>
              <p>
                You can also choose to protect your remaining healthy teeth by getting them crowned or fitted with a bridge for support.
              </p>
              <p>
                Dental Implants are one of the most popular treatments for replacing missing teeth. These implants can replace individual teeth or you can get a complete set of implants put in all at once. Schedule a consultation with your dentist to see if dental implants are right for you.
              </p>
              <p>
                Call and schedule an appointment today!
              </p>
            </div>
          </Container>
        </section>
    </Layout>
  )
}

export default BlogPostTwoPage

export const query = graphql`
  query BlogPostTwoPageQuery {
    hero: file(name: { eq: "hero-image" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    implants_img: file(name: { eq: "dental-implants-blog2" }) {
      childImageSharp {
        fluid(maxWidth: 1080, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
        author
        twitter
      }
    }
  }
`
